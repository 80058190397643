import { IGlobalStore } from './globalStore';

export default class StoreResolver {
  static __store: IGlobalStore;

  static store(): IGlobalStore {
    return StoreResolver.__store;
  }

  static bind(store: IGlobalStore): void {
    StoreResolver.__store = store;
  }
}
