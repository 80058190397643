import Cookies from 'js-cookie';
import lock from '../utils/lockObject';
import merge from 'lodash.mergewith';

import OysterCookie, { IOysterCookieConfig, getCookieConfig, defaultConfig } from './OysterCookie';

/* Cacao Onboarded cookie */
class CacaoOnboardedCookie extends OysterCookie {
  get config() {
    return window.__oyster_cacao_config.config;
  }

  init(vars: IOysterCookieConfig): void {
    if (window.__oyster_cacao_config.config.intialized) return;
    const { isLocalHost, cookieConfig } = getCookieConfig(vars.enviroment, vars.cookieDomain);

    window.__oyster_cacao_config.config = merge({}, defaultConfig, {
      isLocalHost,
      cookieConfig,
      cookieName: vars.cookieName,
      intialized: true,
    });
    lock(window.__oyster_cacao_config);
  }

  getValue(): boolean {
    const cookie: string | undefined = Cookies.get(this.cookieName);
    return cookie === 'true';
  }
}

const CacaoOnboardedCookieInstance = new CacaoOnboardedCookie();

export default CacaoOnboardedCookieInstance;
