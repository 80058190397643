const errors = {
  SERVICE_ERROR: 'SERVICE_ERROR',
  /* user erros */
  PROFILE_PIC: 'PROFILE_PIC',
  INTERCOM_HASH: 'INTERCOM_HASH',
  BUSINESS_DATA: 'BUSINESS_DATA',
  BUSINESS_ADDRESS: 'BUSINESS_ADDRESS',
  BALANCE: 'BALANCE',
  CLABE: 'CLABE',
  BENEFICIARIES: 'BENEFICIARIES',
  OTP_INIT: 'OTP_INIT',
};

export const API_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export { errors };
