import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import defaultConfig, { CustomHttpConfig, HttpConfig } from '../config';
import lock from '../utils/lockObject';
import merge from 'lodash.mergewith';

class ClientFactory {
  static isConfigured: boolean;
  static config: HttpConfig = { ...defaultConfig };

  /**
   * Set http connection configurations
   * @param config
   */
  static configure(config?: CustomHttpConfig): void {
    window.__oyster_client_factory.config = merge(
      {},
      { ...defaultConfig },
      window.__oyster_client_factory.config,
      config,
    );
    window.__oyster_client_factory.isConfigured = true;
  }

  /**
   * To lock config in order to prevent modifications
   * This method should be called after the last endpoint config
   */
  static lockConfig(): void {
    lock(window.__oyster_client_factory);
  }

  /**
   * Register single config
   * @param key
   * @param baseURL
   * @param config
   */
  static addConfig(key: string, baseURL: string, config?: AxiosRequestConfig): void {
    ClientFactory.configure({
      connections: {
        [key]: merge({}, defaultConfig.defaultOptions, config, { baseURL }),
      },
    });
    lock(window.__oyster_client_factory.config.connections);
  }

  /**
   * Remove configs except default
   * @param key
   */
  static removeConfig(key: string): void {
    if (key === window.__oyster_client_factory.config.default) return;
    delete window.__oyster_client_factory.config.connections[key];
  }

  /**
   * Reset configurations to default
   */
  static resetConfig(): void {
    window.__oyster_client_factory.config = defaultConfig;
  }

  /**
   * Get config by connection name
   * It returns default config if is not given
   * @param name
   * @returns {AxiosRequestConfig}
   */
  static getConfig(name?: string): AxiosRequestConfig {
    if (!window.__oyster_client_factory.isConfigured) ClientFactory.configure();
    const configName = name || window.__oyster_client_factory.config.default;
    return window.__oyster_client_factory.config.connections[configName];
  }

  /**
   * Build a new Instance of AxiosInstance
   * @param options AxiosRequestConfig
   * @returns {HttpClient}
   */
  static build(options?: AxiosRequestConfig): AxiosInstance {
    return axios.create({ ...ClientFactory.getConfig(), ...options });
  }

  /**
   * Build a new Instance of AxiosInstance
   * @param name string
   * @param options AxiosRequestConfig
   * @returns {HttpClient}
   */
  static buildByName(name: string, options?: AxiosRequestConfig): AxiosInstance {
    const config = ClientFactory.getConfig(name);
    if (window.__oyster_client_factory.isConfigured && !config) {
      throw new Error(`Not found api connection config with name ${name}`);
    }
    return axios.create({ ...config, ...options });
  }
}

export default ClientFactory;
