import Service from './index';
import { RequestInterceptor, ResponseInterceptor } from '../interceptors';
import AuthorizationRequestInterceptor from '../interceptors/request/AuthorizationRequestInterceptor';
import UnauthorizedResponseInterceptor from '../interceptors/response/UnauthorizedResponseInterceptor';

export default abstract class OysterService extends Service {
  static readonly DEFAULT_CONNECTION: string = 'oyster';

  constructor(
    protected requestInterceptors: RequestInterceptor[] = [],
    protected responseInterceptors: ResponseInterceptor[] = [],
  ) {
    super(
      OysterService.DEFAULT_CONNECTION,
      [AuthorizationRequestInterceptor, ...requestInterceptors],
      [UnauthorizedResponseInterceptor, ...responseInterceptors],
    );
  }
}
