import { AxiosError, AxiosResponse } from 'axios';
import OysterService from '../http/services/OysterService';
import { ISecAuthRequest } from './types';

export default class SecurityAuthorizationService extends OysterService {
  /**
   * Initiates OTP verification
   * @return Promise<AxiosResponse | AxiosError>
   */
  initiateOTPVerification(body: ISecAuthRequest): Promise<AxiosResponse | AxiosError> {
    return this.client.post('/auth/otp/initiate', body);
  }
}
