import oysterTheme from '.';
import { createTheme as createMuiTheme, ThemeOptions, Theme } from '@material-ui/core/styles';

export const contpaqiTheme = {
  // primary
  primary: '#0026A4',
  primaryText: '#323C45',
};

declare module '@material-ui/core/styles' {
  interface Theme {
    contpaqiTheme: typeof contpaqiTheme;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    contpaqiTheme?: typeof contpaqiTheme;
  }
}

const baseTheme: Partial<ThemeOptions> = {
  ...oysterTheme,
  palette: {
    ...oysterTheme.palette,
    primary: {
      ...oysterTheme.palette.primary,
      main: '#0026A4',
    },
    secondary: { ...oysterTheme.palette.secondary, main: '#0026A4', dark: '#0026A4' },
    text: {
      ...oysterTheme.palette.text,
      primary: '#323C45',
    },
    success: {
      ...oysterTheme.palette.success,
      main: '#0026A4',
    },
  },
  typography: {
    ...oysterTheme.typography,
    fontFamily: 'Titillium Web',
    fontSize: 16,
    body1: { fontSize: 18 },
    body2: {
      ...oysterTheme.typography.body2,
      fontFamily: 'Titillium Web',
      fontSize: 16,
      lineHeight: '18px',
      color: oysterTheme.oysterTheme.charcoal,
    },
    h3: {
      ...oysterTheme.typography.h3,
      fontFamily: 'Geogrotesque',
      fontSize: 26,
      fontWeight: 700,
      lineHeight: '32px',
      color: '#323C45',
    },
    h5: {
      ...oysterTheme.typography.h5,
      fontFamily: 'Titillium Web',
      fontSize: 14,
    },
  },
};

export type IContpaqiTheme = keyof Theme['contpaqiTheme'];

const theme = createMuiTheme(baseTheme);

export default theme;
