import lock from '../utils/lockObject';
import merge from 'lodash.mergewith';

import OysterCookie, { IOysterCookieConfig, getCookieConfig, defaultConfig } from './OysterCookie';

/* Security Profile cookie */
class SecurityProfileCookie extends OysterCookie {
  get config() {
    return window.__oyster_security_profile.config;
  }

  init(vars: IOysterCookieConfig): void {
    if (window.__oyster_security_profile.config.intialized) return;
    const { isLocalHost, cookieConfig } = getCookieConfig(vars.enviroment, vars.cookieDomain);

    window.__oyster_security_profile.config = merge({}, defaultConfig, {
      isLocalHost,
      cookieConfig,
      cookieName: vars.cookieName,
      intialized: true,
    });
    lock(window.__oyster_security_profile);
  }
}

const SecurityProfileCookieInstance = new SecurityProfileCookie();

export default SecurityProfileCookieInstance;
