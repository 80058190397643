import { AxiosResponse } from 'axios';
import { ResponseInterceptor } from '../index';
import AuthCookie from '../../cookies/AuthCookie';
import CacaoOnboardedCookie from '../../cookies/CacaoOnboardedCookie';
import SecurityProfileCookie from '../../cookies/SecurityProfileCookie';
import StoreResolver from '../../../storeResolver';
import endpoints from '../../../auth/endpoints';

const AUTH_ERROR = 'AUTH_ERROR';
const PERMISSION_ERROR = 'PERMISSION_ERROR';

const UnauthorizedResponseInterceptor: ResponseInterceptor = async (response: AxiosResponse) => {
  const { error, errorCode, msg } = response.data;
  const requestUrl = response?.config?.url || '';
  const isLogoutRequest = requestUrl === endpoints.logout;
  const noAuthMsg = msg === 'no authorizado';
  const isNoAuth =
    noAuthMsg ||
    errorCode === PERMISSION_ERROR ||
    errorCode === AUTH_ERROR ||
    response.status === 401;

  if (isNoAuth) {
    const token = AuthCookie.getCookie();

    // catch logout error fail and keep on with the cookie delete
    if (!isLogoutRequest) {
      await StoreResolver.store()
        .auth.logout()
        .catch(() => ({}));
    }

    StoreResolver.store().auth.setToken('');
    if (token) AuthCookie.deleteCookie();
    SecurityProfileCookie.deleteCookie();
    CacaoOnboardedCookie.deleteCookie();
    // reload page so cookie is checked again
    window.location.reload();
    return Promise.resolve(AUTH_ERROR);
  }
  if (error) return Promise.reject(response.data);
  return response;
};

export default UnauthorizedResponseInterceptor;
