import { Instance, flow, types } from 'mobx-state-tree';
import Common from '../common';
import { errors } from '../constants';
import { ISecAuthRequest } from './types';

import SecurityAuthorizationService from './service';

export interface IVolatileState {
  service: null | SecurityAuthorizationService;
}

const SecurityAuthorizationStore = types
  .compose(
    Common,
    types.model({
      isFetched: false,
      currentAuthorizationTimestamp: 0,
    }),
  )
  .volatile(
    (): IVolatileState => ({
      service: null,
    }),
  )
  .actions((self) => ({
    updateService: flow(function* () {
      self.service = new SecurityAuthorizationService();
      yield Promise.resolve('ok');
    }),
  }))
  .actions((self) => ({
    initiateOTPVerification: flow(function* (data: ISecAuthRequest) {
      self.submitting = true;
      const {
        deviceId,
        details = '',
        otpVerificationProcessType = 2,
        otpVerificationPurpose,
      } = data;

      try {
        if (!self.service) return Promise.reject(errors.SERVICE_ERROR);

        const { data } = yield self.service.initiateOTPVerification({
          deviceId,
          details,
          otpVerificationProcessType,
          otpVerificationPurpose,
        });

        return data;
      } catch (error) {
        self.setError({
          type: errors.OTP_INIT,
          msg: JSON.stringify(error),
        });
      } finally {
        self.submitting = false;
      }
    }),
  }));

export type TSecurityAuthorizationStore = Instance<typeof SecurityAuthorizationStore>;
export default SecurityAuthorizationStore;
