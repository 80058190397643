import { RequestInterceptor } from '../index';
import AuthCookie from '../../cookies/AuthCookie';

const AuthorizationRequestInterceptor: RequestInterceptor = (config) => {
  const token = AuthCookie.getCookie();
  config.headers.Authorization = token;
  config.headers.Accept = 'application/json';
  config.headers['Device-ID'] = AuthCookie.getDeviceIdCookie() || '';
  config.headers['Device-Type'] = 4;
  config.headers['Content-Type'] = 'application/json';
  config.headers['Accept-Language'] = 'es';
  return config;
};

export default AuthorizationRequestInterceptor;
