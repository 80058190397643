import { ThemeProvider } from '@material-ui/core/styles';
import { FC, createContext, useState } from 'react';

import { themePartnerNames } from '@constants/index';

import oysterTheme from './';
import contpaqiTheme from './contpaqi';

export interface CustomThemeContextProps {
  currentPartnerTheme: string;
  setCurrentPartnerTheme: (partnerName: themePartnerNames) => void;
}

const CustomThemeContext = createContext<CustomThemeContextProps>({
  currentPartnerTheme: themePartnerNames.NONE,
  setCurrentPartnerTheme: () => null,
});

interface CustomThemeProviderProps {
  children: React.ReactNode;
}

const CustomThemeProvider: FC<CustomThemeProviderProps> = ({ children }) => {
  const [currentPartnerTheme, _setCurrentPartnerTheme] = useState<themePartnerNames>(
    themePartnerNames.NONE,
  );

  const setCurrentPartnerTheme = (partnerName: themePartnerNames) => {
    _setCurrentPartnerTheme(partnerName);
  };

  const themes = {
    [themePartnerNames.NONE]: oysterTheme,
    [themePartnerNames.CONTPAQI]: contpaqiTheme,
  };

  const contextValue = {
    currentPartnerTheme,
    setCurrentPartnerTheme,
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={themes[currentPartnerTheme as themePartnerNames]}>
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export { CustomThemeContext, CustomThemeProvider };
