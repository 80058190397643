import Cookies from 'js-cookie';
import lock from '../utils/lockObject';
import merge from 'lodash.mergewith';

import OysterCookie, {
  IOysterCookieConfig,
  getExpiration,
  getCookieConfig,
  defaultConfig,
} from './OysterCookie';

const deviceIdCookie = 'oysDeviceId';

/* Auth cookie */
class AuthCookie extends OysterCookie {
  get config() {
    return window.__oyster_auth_cookie.config;
  }

  get deviceIdExpMins() {
    return this.config.deviceIdExpMins || defaultConfig.deviceIdExpMins;
  }

  init(vars: IOysterCookieConfig): void {
    if (window.__oyster_auth_cookie.config.intialized) return;
    const { isLocalHost, cookieConfig } = getCookieConfig(vars.enviroment, vars.cookieDomain);

    window.__oyster_auth_cookie.config = merge({}, defaultConfig, {
      isLocalHost,
      cookieConfig,
      cookieName: vars.cookieName,
      intialized: true,
    });
    lock(window.__oyster_auth_cookie);
  }

  setCookie(value: string): void {
    Cookies.set(this.cookieName, value, {
      expires: getExpiration(45),
      ...this.cookieConfig,
    });
  }

  setDeviceIdCookie(value: string): void {
    Cookies.set(deviceIdCookie, value, {
      expires: getExpiration(this.deviceIdExpMins),
      ...this.cookieConfig,
    });
  }

  getDeviceIdCookie(): string {
    const cookie: string | undefined = Cookies.get(deviceIdCookie);
    return cookie || '';
  }

  deleteDeviceIdCookie(): void {
    Cookies.remove(deviceIdCookie, this.cookieConfig);
  }
}

const AuthCookieInstance = new AuthCookie();

export default AuthCookieInstance;
