import { AxiosRequestConfig } from 'axios';

export interface HttpConnections {
  [name: string]: AxiosRequestConfig;
}

export interface HttpConfig {
  default: keyof HttpConnections;
  defaultOptions: Partial<AxiosRequestConfig>;
  connections: HttpConnections;
}

export interface IGlobalConfig {
  config: HttpConfig;
  isConfigured: boolean;
}

export type CustomHttpConfig = Partial<HttpConfig>;

const config: HttpConfig = {
  default: 'api',
  defaultOptions: {
    timeout: 30000,
  },
  connections: {
    api: {
      baseURL: '/api',
    },
  },
};

if (!window.__oyster_client_factory) {
  Object.defineProperty(window, '__oyster_client_factory', {
    value: {
      config: config,
      isConfigured: false,
    },
  });
}

export default Object.freeze(config);
