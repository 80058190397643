import Cookies from 'js-cookie';

/* Cookie setting utils */
export const getExpiration = (minutes: number): Date =>
  new Date(new Date().getTime() + 60 * minutes * 1000);

export const getCookieConfig = (env: string, domain: string): Record<string, unknown> => {
  const isLocalHost = env === 'test' ? false : window.location.hostname === 'localhost';
  const cookieConfig = isLocalHost ? {} : { domain };
  return { isLocalHost, cookieConfig };
};
export const defaultConfig = {
  cookieName: '',
  isLocalHost: true,
  expMins: 21600,
  deviceIdExpMins: 21600,
  cookieConfig: {},
  intialized: false,
};

/* Define different cookie configs set in window */
if (!window.__oyster_auth_cookie) {
  Object.defineProperty(window, '__oyster_auth_cookie', {
    value: {
      config: defaultConfig,
    },
  });
}

if (!window.__oyster_cacao_config) {
  Object.defineProperty(window, '__oyster_cacao_config', {
    value: {
      config: defaultConfig,
    },
  });
}

if (!window.__oyster_security_profile) {
  Object.defineProperty(window, '__oyster_security_profile', {
    value: {
      config: defaultConfig,
    },
  });
}

export interface IOysterCookieConfig {
  enviroment: string;
  cookieName: string;
  cookieDomain: string;
}

export interface IOysterConfig {
  cookieName: string;
  isLocalHost: boolean;
  expMins: number;
  deviceIdExpMins: number;
  cookieConfig: Record<string, unknown>;
  intialized: boolean;
}

export interface IGlobalCookieConfig {
  config: IOysterConfig;
}

type TWindowKey = '__oyster_auth_cookie' | '__oyster_cacao_config' | '__oyster_security_profile';

/* Generic Oyster cookie */
class OysterCookie {
  windowKey: '' | '__oyster_auth_cookie' | '__oyster_cacao_config' | '__oyster_security_profile';

  constructor() {
    this.windowKey = '';
  }

  get config(): IOysterConfig {
    return window[this.windowKey as TWindowKey].config;
  }

  get cookieName(): string {
    return this.config.cookieName || defaultConfig.cookieName;
  }

  get isLocalHost(): boolean {
    return this.config.isLocalHost || defaultConfig.isLocalHost;
  }

  get expMins(): number {
    return this.config.expMins || defaultConfig.expMins;
  }

  get cookieConfig(): Record<string, unknown> {
    return this.config.cookieConfig || defaultConfig.cookieName;
  }

  get intialized(): boolean {
    return this.config.intialized || defaultConfig.intialized;
  }

  setCookie(value: string, expMins?: number): void {
    Cookies.set(this.cookieName, value, {
      expires: getExpiration(expMins || this.expMins),
      ...this.cookieConfig,
    });
  }

  getCookie(): string {
    const cookie: string | undefined = Cookies.get(this.cookieName);
    return cookie || '';
  }

  deleteCookie(): void {
    Cookies.remove(this.cookieName, this.cookieConfig);
  }
}

export default OysterCookie;
