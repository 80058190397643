import { Instance, applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import ClientFactory from './http/factory';
import { CustomHttpConfig } from './http/config';
import { AxiosRequestConfig } from 'axios';

export interface TError {
  type: string;
  msg?: string;
}

export const ErrorModel = types
  .model({
    type: types.string,
    msg: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setError: ({ type, msg = '' }: TError) => {
      self.type = type;
      self.msg = msg;
    },
  }));

export type TErrorModel = Instance<typeof ErrorModel>;

const CommonStore = types
  .model({
    submitting: false,
    fetching: false,
    error: types.maybeNull(ErrorModel),
  })
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        self.error = ErrorModel.create({ type: '', msg: '' });
        initialState = getSnapshot(self);
      },
      reset: () => {
        applySnapshot(self, initialState);
      },
    };
  })
  .actions((self) => ({
    setError: ({ type, msg = '' }: TError) => {
      self.error?.setError({ type, msg });
    },
    resetError: () => {
      self.error?.setError({ type: '', msg: '' });
    },
    configure: (config: CustomHttpConfig) => {
      ClientFactory.configure(config);
    },
    addConfig: (key: string, baseURL: string, config?: AxiosRequestConfig) => {
      ClientFactory.addConfig(key, baseURL, config);
    },
    toggleSubmit: () => {
      self.submitting = !self.submitting;
    },
    replace(data: typeof self) {
      applySnapshot(self, data);
    },
  }));

export type TCommonStore = Instance<typeof CommonStore>;
export default CommonStore;
