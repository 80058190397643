import ClientFactory from '../factory';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { RequestInterceptor, ResponseInterceptor } from '../interceptors';

export default abstract class Service {
  static readonly DEFAULT_CONNECTION: string = 'api';

  /**
   * @var AxiosInstance
   * */
  client: AxiosInstance;
  protected __requestInterceptors: RequestInterceptor[];
  protected __responseInterceptors: ResponseInterceptor[];

  constructor(
    protected connection: string = Service.DEFAULT_CONNECTION,
    protected requestInterceptors: RequestInterceptor[] = [],
    protected responseInterceptors: ResponseInterceptor[] = [],
  ) {
    this.client = this.buildClient();
    this.__requestInterceptors = requestInterceptors;
    this.__responseInterceptors = responseInterceptors;
    this.applyRequestInterceptors();
    this.applyResponseInterceptors();
  }

  buildClient(): AxiosInstance {
    return ClientFactory.buildByName(this.connection);
  }

  /**
   * Return axios config for current instance
   */
  get config(): AxiosRequestConfig {
    return this.client.defaults;
  }

  /**
   * Apply interceptors before each request
   * @private
   */
  applyRequestInterceptors(): void {
    this.getRequestInterceptors().forEach((interceptor) => {
      this.client.interceptors.request.use(interceptor);
    });
  }

  /**
   * Apply interceptors before each request
   * @private
   */
  applyResponseInterceptors(): void {
    this.getResponseInterceptors().forEach((interceptor) => {
      this.client.interceptors.response.use(interceptor);
    });
  }

  /**
   * Get the instance connection name
   */
  getConnectionName(): string {
    return this.connection;
  }

  /**
   * Get an array of request interceptors
   * @return {RequestInterceptor[]}
   */
  getRequestInterceptors(): RequestInterceptor[] {
    return this.__requestInterceptors;
  }

  /**
   * Get an array of response interceptors
   * @return {ResponseInterceptor[]}
   */
  getResponseInterceptors(): ResponseInterceptor[] {
    return this.__responseInterceptors;
  }
}
